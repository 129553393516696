.station-slider {
  position: relative;

  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }

  .react-multi-carousel-list {
    align-items: normal;
  }

  .react-multi-carousel-item button {
    transition: opacity 0.4s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  button {
    h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 7px;
    }

    .gradient-badge {
      position: absolute;
      top: 11px;
      left: 7px;
    }
  }
}

.gradient-badge {
  background: linear-gradient(
    257.44deg,
    #f27121 0%,
    #e94057 49.32%,
    #8a2387 100%
  );
  box-shadow: 0 0 5px 0 #646464;
  padding: 2px 7px;
  border-radius: 54px;
  color: #fff;
}

form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0;
}

.react-multi-carousel-item {
  button {
    width: 100%;
    height: 100%;
    aspect-ratio: auto 1 / 1;
  }

  img {
    object-fit: cover;
    vertical-align: bottom;
  }
}
