%heading {
  font-family: $font-primary;
  color: $color-primary;
  font-weight: 400;
}

h1 {
  @extend %heading;
  font-size: 3.056rem;
  line-height: 1;
  font-family: $font-primary;
  font-weight: 900;
  font-style: normal;
}

h2 {
  @extend %heading;
  font-size: 2rem;
  line-height: 1;
  font-weight: 600;
  font-style: normal;

  &.title {
    font-family: $font-display;
    font-weight: 900;
  }
}

h3 {
  @extend %heading;
  font-size: 1.6rem;
  font-weight: 600;
}

h4 {
  @extend %heading;
  font-size: 1.34rem;
  font-weight: 700;
}

h5 {
  @extend %heading;
  font-size: 1rem;
  font-weight: 600;
}

h6 {
  @extend %heading;
  font-size: 0.89rem;
  font-weight: 700;
}

.icon-button {
  color: #fff;
}

// .h4 {
//     @extend %heading;
//     font-size: 1.9rem;

//     &--light { color: $color-grey-light-1; }
//     &--dark { color: $color-grey-dark-1; }
// }

// .btn {
//     background-color: $color-primary;
//     color: #fff;
//     border: none;
//     border-radius: 0;
//     font-family: $font-display;
//     font-size: 1.5rem;
//     text-transform: uppercase;
//     padding: 1.8rem 3rem;
//     cursor: pointer;
//     transition: all .2s;

//     &:hover {
//         background-color: $color-primary-dark;
//     }
// }

.relative-layout {
  position: relative;
}

a {
  color: $color-primary;
  text-decoration: none;
}

.bg-cl-3 {
  background-color: $bg-color-3;
}

.radius-13 {
  border-radius: 13px;
}

.radius-19 {
  border-radius: 19px;
}

.lt-grey-1 {
  color: $color-grey-light-1;
}

.lt-grey-2 {
  color: $color-grey-light-2;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.ft-md {
  font-size: 1.12rem;
}

.ft-14 {
  font-size: 0.78rem;
}

.ft-20 {
  font-size: 1.112rem;
}

.ft-24 {
  font-size: 1.34rem;
}

.ft-30 {
  font-size: 1.67rem;
}

.ft-16 {
  font-size: 0.89rem;
}

.ft-18 {
  font-size: 1rem;
}

// .mb-sm { margin-bottom: 2rem; }
.mb-md {
  margin-bottom: 1.78rem;
}
.mb-lg {
  margin-bottom: 3rem;
}
.mb-hg {
  margin-bottom: 8rem;
}

.mb-5 {
  margin-bottom: 0.28rem;
}

.mb-10 {
  margin-bottom: 0.56rem;
}

.mb-15 {
  margin-bottom: 0.84rem;
}

.mb-18 {
  margin-bottom: 1rem;
}

.mb-20 {
  margin-bottom: 1rem;
}

.mb-25 {
  margin-bottom: 1.39rem;
}

.mb-30 {
  margin-bottom: 1.67rem;
}

.mb-35 {
  margin-bottom: 1.95rem;
}

.mb-45 {
  margin-bottom: 3rem;
}

.mb-100 {
  margin-bottom: 5.56rem;
}

.mt-8 {
  margin-top: 0.45rem;
}

.mt-10 {
  margin-top: 0.56rem;
}

.mt-15 {
  margin-top: 0.84rem;
}

.mt-18 {
  margin-top: 1rem;
}

.mt-20 {
  margin-top: 1.12rem;
}

.mt-25 {
  margin-top: 1.12rem;
}

.mt-30 {
  margin-top: 1.67rem;
}

.mt-35 {
  margin-top: 1.95rem;
}

.mt-45 {
  margin-top: 2.5rem;
}

.mt-50 {
  margin-top: 2.78rem;
}

.mt-100 {
  margin-top: 5.56rem;
}

.mr-6 {
  margin-right: 0.39rem;
}

.mr-18 {
  margin-right: 1rem;
}

.mr-20 {
  margin-right: 1.112rem;
}

.ml-17 {
  margin-left: 0.95rem;
}

.ml-20 {
  margin-left: 1.112rem;
}

.pd-l-r-16 {
  padding: 0 1rem;
}

.pd-l-r-30 {
  padding: 0 1.67rem;
}

.pd-l-r-40 {
  padding: 0 2.23rem;
}

.pt-36 {
  padding-top: 2rem;
}

.m-p-24-10 {
  margin: 24px;
  padding: 10px;
}

.bttn-normal {
  letter-spacing: 0.05rem;
  font-weight: 500;
  height: 53px;
  padding: 0 2rem;

  span {
    font-weight: 500;
  }
}

// FORM STYLE

// checkbox
.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.12em;
  height: 1.12em;
  border: 0.15em solid #fff;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

button.bttn-transparent {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.eight-group-badge {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 39px;
  display: flex;
  font-size: 0.89rem;
  height: 27px;
  align-items: center;
  width: 197px;
  align-content: center;
  grid-gap: 0 8px;
  gap: 0 10px;
  width: fit-content;

  &.inner {
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin: 0;
  }

  .separator {
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.5);
  }
}

.clr-white {
  color: #fff;
}

.width-100 {
  width: 100%;
}

// .ht-40 {
//   height: ;
// }

.ht-56 {
  height: 56px;
}

.ht-60 {
  height: 60px;
}

.grey-lt-1 {
  color: $color-grey-light-1;
}

.width-346 {
  max-width: 346px;
}

.align-center {
  text-align: center;
}

.flex-end-wrp {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiButton-root {
  font-weight: 600;
  text-transform: none;
  text-align: center;

  &.fw-500 {
    font-weight: 500;
  }

  &.bttn-gradient {
    background: linear-gradient(257.44deg, #f27121 0%, #e94057 49.32%, #8a2387 100%);
    border: 3.58646px solid #212121;
    border-radius: 54px;
    color: #fff;
  }

  &.bttn-grey {
    background: rgba(33, 33, 33, 0.99);
    /* Dark - Outside */

    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25),
      inset -3px -3px 10px rgba(49, 49, 49, 0.25);
    border-radius: 31px;
  }
}

.bttn-grey {
  background: #212121;
  border: 3.59px solid #212121;
  border-radius: 119.549px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25),
    inset -3px -3px 10px rgba(49, 49, 49, 0.25);
  cursor: pointer;
}

.width-425 {
  max-width: 425px;
  width: 100%;
}

.gradient-tags .MuiChip-deleteIcon {
  color: #7e7e7e;
}

.gradient-tags .MuiChip-label,
.gradient-text {
  background: linear-gradient(257.44deg, #f27121 0%, #e94057 49.32%, #8a2387 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MuiChip-root {
  background: #2b2b2b;
  border-radius: 19px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  height: 42px;

  .MuiChip-label {
    padding: 0 0.89rem;
  }
}

.switch-label {
  color: #fff;
  justify-content: space-between;
  margin: 0;
}

.MuiDialog-container .MuiPaper-root {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
  border-radius: 19px;
}
