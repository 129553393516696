// COLOR VARIABLES
$color-primary: #fff;
$color-grey-light-1: rgba(255, 255, 255, 0.5);
$color-grey-light-2: rgba(255, 255, 255, 0.78);
$color-grey-light-3: rgba(255, 255, 255, 0.76);
$color-gradient: linear-gradient(257.44deg, #F27121 0%, #E94057 49.32%, #8A2387 100%);

// BG VARIABLES
$bg-color: #121212;
$bg-color-1: #2A2A2A;
$bg-color-2: #202020;
$bg-color-3: #212121;

// FONT VARIABLES
$font-primary: 'SF Pro Display', sans-serif;
$font-display: 'Nekst', sans-serif;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 112.5%; // 10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: $bp-largest) {
    font-size: 100%;
  }
}

body {
  font-family: $font-primary;
  color: $color-primary;
  font-weight: 400;
  line-height: 1.41;
  background-color: $bg-color;
}