.profile-page-container {
  display: grid;
  grid-template-rows: minmax(305px, 50vh) minmax(280px, calc(50vh - 87px));
  grid-template-columns: minmax(310px, 1.2fr) minmax(500px, 2.8fr);

  &.col-layout {
    grid-template-rows: minmax(700px, calc(100vh - 87px));
  }

  grid-template-areas:
    "side box1"
    "side box2";
  grid-gap: 0 34px;

  .profile-page-sidebar {
    grid-area: side;
  }

  .user-detail-wrapper-1 {
    margin: 1.78rem 0 0 1.89rem;
    background: #212121;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    overflow: hidden;
    position: relative;
    height: 100%;

    .rcs-inner-container {
      padding: 0;
    }
  }

  .user-detail-header1 {
    min-height: 412px;
  }

  .station-profile-mr {
    margin: 1.78rem 1.89rem 0 0;
  }

  .scheduled-station-wrapper li > div {
    margin-right: 1.34rem;
  }

  //   @media only screen and (max-width: $bp-large) {
  //     grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
  //     grid-template-columns:
  //       [full-start] minmax(6rem, 1fr) [center-start] repeat(
  //         8,
  //         [col-start] minmax(min-content, 14rem) [col-end]
  //       )
  //       [center-end] minmax(6rem, 1fr) [full-end];
  //   }

  h1 {
    width: 479px;
  }

  .hero-banner-2 {
    grid-column: col2-start / col2-end;
    grid-row: box1-start / box2-end;
    background: url(../images/herobanner2.png) no-repeat bottom right;
    background-size: cover;
  }

  .station-profile-wrapper .react-multi-carousel-item > div {
    margin-right: 1.34rem;
  }

  .station-slider {
    button {
      max-width: 202.83px;
      max-height: 202.83px;
    }

    h5 {
      text-align: left;
    }
  }
}

.slide-wrapper {
  top: 100%;
  position: absolute;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: #212121;
  height: 100%;
  width: 100%;

  .MuiListItemIcon-root {
    min-width: 73px;
  }

  &.active {
    top: 0;
    z-index: 5;
  }
}

// @media only screen and (max-width: 1070) {
//   .user-detail-wrapper-1 .MuiAvatar-root {
//     width: 100px;
//     height: 100px;
//   }
// }
