@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/sf-pro-display/SFProDisplay-Regular/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/sf-pro-display/SFProDisplay-Regular/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro-display/SFProDisplay-Medium/SFProDisplay-Medium.woff2') format('woff2'),
      url('../fonts/sf-pro-display/SFProDisplay-Medium/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro-display/SFProDisplay-Bold/SFProDisplay-Bold.woff2') format('woff2'),
      url('../fonts/sf-pro-display/SFProDisplay-Bold/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nekst';
  src: url('../fonts/nekst-black/Nekst-Black.woff2') format('woff2'),
    url('../fonts/nekst-black/Nekst-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
