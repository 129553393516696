.auth-page-container {
  display: grid;
  width: 95.6%;
  margin: 40px auto 0 auto;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: repeat(2, [col-start] minmax(6rem, 1fr) [col-end]);
  grid-gap: 20px;

  //   @media only screen and (max-width: $bp-large) {
  //     grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
  //     grid-template-columns:
  //       [full-start] minmax(6rem, 1fr) [center-start] repeat(
  //         8,
  //         [col-start] minmax(min-content, 14rem) [col-end]
  //       )
  //       [center-end] minmax(6rem, 1fr) [full-end];
  //   }

  //   @media only screen and (max-width: $bp-medium) {
  //     grid-template-rows: 6rem calc(100vh - 6rem);
  //   }
}

.download-app {
  grid-column: col-start 1 / col-end 2;
  padding: 7.35rem 0 7.35rem 6.6vw;
  background: url(../images/herobanner1.png) no-repeat bottom right #212121;
  background-size: 1115px;

  & > div {
    width: 22.4rem;
  }

  @media (max-width: 1600px) {
    background-size: 60%;
  }

  @media (max-width: 1425px) {
    background-size: 808px;
  }

  @media (max-width: 1024px) {
    background-position-x: 195px;
  }
}

.grid-block-1 {
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-row-gap: 2rem;
  padding: 5.95rem 0;
  overflow: hidden;
  background: url("../images/bg-gradient.svg") no-repeat center #212121;
  background-size: contain;
}

footer {
  width: 100%;
  padding: 55px 0;
  text-align: center;
}

.icon {
  font-size: 0;
  display: inline-block;

  &.right-arrow {
    width: 6px;
    height: 12px;
    background: url(../images/right-arrow.svg) no-repeat center;
    margin-left: 1rem;
  }
}

.qr-image-wrapper {
  width: 256px;
  height: 256px;
}

.home-slider-wrp {
  text-align: center;
  padding-top: 3.39rem;
  position: relative;
}

.home-slider-wrp {
  p {
    color: $color-grey-light-3;
    max-width: 323px;
    margin: auto;
  }

  .react-multi-carousel-dot-list {
    bottom: 35px;

    li {
      display: inline-block;

      &.react-multi-carousel-dot button {
        font-size: 0;
        line-height: 0;
        display: block;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        width: 12px;
        height: 12px;
        background: #3c3c3c;
        margin-right: 12px;
        border-radius: 20px;
        transition: 0.3s ease width;

        &:hover {
          background-color: #424242;
        }
      }

      &.react-multi-carousel-dot.react-multi-carousel-dot--active button {
        border-radius: 16px;
        background: linear-gradient(
          257.44deg,
          #f27121 0%,
          #e94057 49.32%,
          #8a2387 100%
        );
        width: 24px;
        height: 12px;
      }
    }
  }
}

.eight-otp-wrp {
  input {
    width: 49px;
    height: 61px;
    padding: 0;
    font-size: 26px;
    text-align: center;
    margin-right: 11px;
    text-transform: uppercase;
    color: #fff;
    background: #2b2b2b;
    background-clip: padding-box;
    border-radius: 12px;
    border: 3px solid transparent;
    font-family: $font-primary;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      margin-right: 8px;
    }

    // &:focus {
    //   // border-image-source: linear-gradient(rgba(0, 51, 102, 0.5), rgba(0, 0, 51, 0.5));

    //   border-image-source: linear-gradient(
    //     257.44deg,
    //     #f27121 0%,
    //     #e94057 49.32%,
    //     #8a2387 100%
    //   );
    //   background-clip: padding-box, border-box;
    //   border-image-slice: 1;
    //   border-radius: 12px;
    // }
  }
}
