.home-page-container {
  display: grid;
  margin-left: 9%;
  grid-template-rows: minmax(280px, 40vh) minmax(233px, calc(60vh - 177px));
  grid-gap: 90px 0;
  background: url(../images/herobanner2.png) no-repeat bottom right;
  background-size: contain;
  //   @media only screen and (max-width: $bp-large) {
  //     grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
  //     grid-template-columns:
  //       [full-start] minmax(6rem, 1fr) [center-start] repeat(
  //         8,
  //         [col-start] minmax(min-content, 14rem) [col-end]
  //       )
  //       [center-end] minmax(6rem, 1fr) [full-end];
  //   }

  //   @media only screen and (max-width: $bp-medium) {
  //     grid-template-rows: 6rem calc(100vh - 6rem);
  //   }

  h1 {
    width: 479px;
  }

  .react-multi-carousel-list {
    height: 174px;
  }

  .station-slider {
    max-width: 580px;
    max-height: 180px;
    position: relative;

    button {
      max-width: 174px;
      max-height: 174px;
      // padding-right: 1.17rem;

      // &:last-child {
      //   padding: 0;
      // }
    }

    h5 {
      text-align: left;
    }
  }

  .box-1-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-end;
    align-self: flex-end;
  }
}

.golive-dialog-wrapper {
  display: grid;
  grid-template-rows: minmax(285px, 587px);
  grid-template-columns: repeat(2, [col-start] minmax(6rem, 1fr) [col-end]);

  .station-banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &::after {
      position: absolute;
      width: 218px;
      height: 100%;
      right: 0;
      top: 0;
      content: "";
      display: block;
      background: linear-gradient(
        90deg,
        rgba(33, 33, 33, 0) 0%,
        rgba(33, 33, 33, 0.72) 52.67%,
        #212121 100%
      );
    }
  }

  .station-description {
    padding: 2.62rem 1.095rem 3.34rem 3.95rem;

    .rcs-inner-container {
      padding-right: 45px;
    }
  }
}

.create-station-bttn {
  width: 270px;
  height: 65px;
}

.icon-pg {
  background: url(../images/people-group.svg) no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

// .react-multi-carousel-track li:first-child {
//   padding-left: 0;
// }

.carousel-button-group {
  button.disable {
    display: none;
  }

  // .prev-bttn {
  //   left: -24px;
  // }

  .next-bttn {
    right: -24px;
  }
}
