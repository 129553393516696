.station-form-dialog-wrp {
  padding: 5rem 2rem;
}

.eight-dialog-overflow {
  display: grid;
  grid-template-rows: minmax(300px, 800px);
  height: calc(100vh - 100px);

  .rcs-custom-scrollbar {
    right: 18px;
  }
}
