.session-page-container {
  display: grid;
  grid-template-rows: minmax(500px, calc(100vh - 187px)) 100px;
  grid-template-columns: minmax(255px, 1fr) minmax(572px, 3fr) minmax(
      300px,
      1.4fr
    );
  grid-template-areas:
    "people midbox playlist"
    "player player player";

  .people-sidebar {
    grid-area: people;
    background: #212121;
    // padding: 2.5rem 1.5rem 0 1.62rem;

    .avatar-outlay {
      border-radius: 50%;
      padding: 2px;

      &.cohost {
        background: linear-gradient(180deg, #2ed367 0%, #248e49 100%);
        box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.25),
          -5px -5px 10px rgba(51, 51, 51, 0.25),
          inset -3px -3px 10px rgba(49, 49, 49, 0.25);
      }

      &.muted {
        background: linear-gradient(
          257.44deg,
          #f27121 0%,
          #e94057 49.32%,
          #8a2387 100%
        );
      }
    }

    h4 {
      padding: 2.5rem 1.5rem 0 1.62rem;
    }

    .MuiSvgIcon-root {
      color: #fff;
    }

    .MuiListItemIcon-root {
      min-width: 46px;
    }

    .MuiDivider-root {
      background-color: rgb(255 255 255 / 10%);
    }

    .MuiList-root .MuiTypography-root {
      font-size: 0.89rem;
      font-weight: 600;
    }
  }

  .mid-container {
    grid-area: midbox;
    padding: 2.12rem 1.34rem 0 1.34rem;

    .chat-inner-container {
      display: flex;
      flex-direction: column;
      align-content: stretch;
    }

    .mid-inner-content {
      display: grid;
      grid-template-rows: 68px minmax(400px, 1fr);
      height: 100%;
      grid-gap: 25px;
    }

    .chat-header-icons {
      display: flex;
      gap: 20px;
    }
  }

  .tracklist-sidebar {
    grid-area: playlist;
    background: #202020;

    .rcs-inner-container {
      padding: 0;
    }
  }

  .player-footer {
    grid-area: player;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.77rem;
  }
}

.sys-mute-wrp {
  width: 160px;
  height: 100px;
  background: url("../images/bg-gradient.svg") no-repeat center #121212;
  background-size: contain;
}

.player-footer {
  .player-footer-description {
    display: flex;
    align-items: center;
    width: calc(45% - 125px);

    p {
      font-size: 0.89rem;
      color: rgb(255 255 255 / 50%);
    }

    .player-footer-content {
      padding: 0 1.5rem 0 1rem;
    }
  }
}

.mic-live-badge {
  background: #fff;
}

.auto-img-dim {
  img {
    width: auto;
    height: auto;
  }
}

.edit-profile-badge {
  background: linear-gradient(
    257.44deg,
    #f27121 0%,
    #e94057 49.32%,
    #8a2387 100%
  );
}

.pin-msg-wrapper {
  background: #2a2a2a;
  /* Dark - Inside */

  box-shadow: 4px 4px 4px rgba(27, 27, 27, 0.25),
    -2px -2px 5px rgba(37, 37, 37, 0.25),
    inset 4px 4px 10px rgba(10, 10, 10, 0.25),
    inset -6px -6px 10px rgba(49, 48, 48, 0.25);
  border-radius: 100px;
  transform: rotate(-0.19deg);
  padding: 0.95rem 1.17rem;

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 20px;
  }
}

.eight-chat-box {
  background-color: $bg-color-1;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 1.39rem 0 1.39rem 1.12rem;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(300px, 1fr) 54px;

  &.pin-msg-active {
    height: calc(100% - 85px);
  }

  .chat-frame-foot {
    display: flex;
    align-items: flex-end;
    padding-right: 1.12rem;
  }

  .chat-frame-foot-1 {
    width: calc(100% - 35px);
  }

  .chat-textbar {
    width: 100%;
    padding: 17px 72px 16px 17px;
    background: #212121;
    border-radius: 100px;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
  }

  .send-text-bttn {
    position: absolute;
    right: 14px;
    top: 9px;
  }

  .eight-chat-frame {
    padding-bottom: 20px;

    .chat-grid {
      margin-bottom: 1rem;
      display: grid;
      flex-direction: column;

      &:last-child {
        margin: 0;
      }
    }

    p {
      display: inline-flex;
      border-radius: 16px;
      padding: 0.67rem 0.84rem;
      position: relative;
    }

    small {
      font-size: 0.67rem;
      color: $color-grey-light-1;
    }

    .left-col {
      .time-wrap {
        margin-left: 32px;
      }

      p {
        background: #3c3c3c;
        border-bottom-left-radius: 0;
        margin-left: 8px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: -14px;
          width: 19px;
          height: 12px;
          background: radial-gradient(
            circle at top left,
            transparent 58%,
            #3c3c3c 60%
          );
        }
      }
    }

    .right-col {
      justify-content: flex-end;

      p {
        background: linear-gradient(
          257.44deg,
          #f27121 0%,
          #e94057 49.32%,
          #8a2387 100%
        );
        border-bottom-right-radius: 0;
        margin-right: 8px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          right: -12px;
          width: 17px;
          height: 12px;
          background: radial-gradient(
            circle at top right,
            transparent 58%,
            #f0672c 60%
          );
        }
      }

      .time-wrap {
        text-align: right;
      }
    }
  }
}

.userdetail-wrapper2 {
  padding: 2.39rem 2.62rem;
  min-height: 362.86px;

  .station-title {
    margin: 2.23rem 0 0.84rem 0;
  }

  .station-slider {
    img {
      max-width: 165px;
      max-height: 165px;
    }

    button {
      margin-right: 16px;
    }
  }

  .follow-bttn {
    padding: 0 2.2rem;
    font-size: 18px;
    font-weight: 500;
    height: 40px;
  }

  .user-detail-header1 {
    grid-template-columns: minmax(6rem, 1fr) minmax(6rem, 3fr);
    display: grid;
    grid-gap: 3rem;
    min-height: 112px;
  }

  .separator {
    width: 1px;
    height: 19px;
    background: #3c3c3c;
  }
}

.flex-box {
  display: flex;

  &.items-center {
    align-items: center;
  }

  &.content-center {
    align-content: center;
  }
}

.playlist-tab > div {
  height: calc(100vh - 273px);
  overflow: hidden;
}

.session-page-container .mid-container .chat-header-icons {
  @media (max-width: 1310px) {
    gap: 10px;
  }
}
